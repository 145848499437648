<template>
  <div>
    <TheYaTeCommonHeader />
    <TheYaTeCommonNote class="bg-co-gray-100" />
    <main class="bg-co-gray-100">
      <slot />
    </main>
    <TheYaTeCommonFooterSimple />
  </div>
</template>

<script setup lang="ts">
import TheYaTeCommonHeader from '~/components/YaTe/YaTeCommon/Header/TheYaTeCommonHeader.vue'
import TheYaTeCommonNote from '~/components/YaTe/YaTeCommon/TheYaTeCommonNote.vue'
import TheYaTeCommonFooterSimple from '~/components/YaTe/YaTeCommon/Footer/TheYaTeCommonFooterSimple.vue'

useHead({
  htmlAttrs: {
    class: '!bg-black',
    'data-theme': 'lineyahoo',
  },
  bodyAttrs: {
    class: 'bg-co-gray-200',
  },
  link: [
    {
      hid: "favicon",
      rel: 'icon',
      type: 'image/x-icon',
      href: '/favicon-yate.ico',
    },
  ],
})
</script>


